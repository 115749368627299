import React, { useState, useEffect } from 'react';
import '../App.css';
import cenovnik1 from '../data/cenovnik.json';
import cenovnik2 from '../data/cenovnik-estetske.json';
import cenovnik3 from '../data/cenovnik-psiholog.json';
import cenovnik4 from '../data/cenovnik-tehnika.json';

const Cenovnik = () => {
    const [cenovnikData1, setCenovnikData1] = useState([]);
    const [cenovnikData2, setCenovnikData2] = useState([]);
    const [cenovnikData3, setCenovnikData3] = useState([]);
    const [cenovnikData4, setCenovnikData4] = useState([]);

    useEffect(() => {
        setCenovnikData1(cenovnik1);
        setCenovnikData2(cenovnik2);
        setCenovnikData3(cenovnik3);
        setCenovnikData4(cenovnik4);
    }, []);

    return (
        <div className='uslugeback'>

            <div className="container pt-5 pb-5 mb-0">
                <h3 className="mb-4 tim">CENOVNIK</h3>
                <p className='cenovnik-usluge'>*U cenu nisu uključeni lekovi (kateter)<br />
                Sve cene su izražene u dinarima.</p>
                <h5 className='mt-4 mb-4 pregled'><b>LEKARSKE USLUGE</b></h5>
                <div className="cenovnik-header mb-2">
                    <div className="row">
                        <div className="col-md-4">
                            <h5 className='cenatitle nazivusl'>NAZIV USLUGE</h5>
                        </div>
                        <div className="col-md-4 ">
                            <h5 className='cenatitle'>U ORDINACIJI</h5>
                        </div>
                        <div className="col-md-4 ">
                            <h5 className='cenatitle'>NA TERENU</h5>
                        </div>
                    </div>
                </div>
                {cenovnikData1.map((item, index) => (
                    <div key={index} className="cenovnik-item mb-2">
                        <div className="row g-0">
                            <div className="col-md-4 col-6 naziv">
                                <h5 className='naziv'> {item.naziv}</h5>
                            </div>
                            <div className="col-md-4  col-3 cena">
                                <h5 className='cenatitle'>{item.cenaOrdinacija}</h5>
                            </div>
                            <div className="col-md-4  col-3 cena">
                                <h5 className='cenatitle'>{item.cenaTerena}</h5>
                            </div>
                        </div>
                    </div>
                ))}

                <h5 className='mt-4 mb-4 pregled'><b>ESTETSKE PROCEDURE</b></h5>

                <div className="cenovnik-header mb-2">
                    <div className="row">
                        <div className="col-md-4 ">
                            <h5 className='cenatitle nazivusl'>NAZIV USLUGE</h5>
                        </div>
                        <div className="col-md-4 ">
                            <h5 className='cenatitle'>U ORDINACIJI</h5>
                        </div>
                        <div className="col-md-4">
                            <h5 className='cenatitle'>NA TERENU</h5>
                        </div>
                    </div>
                </div>
                {cenovnikData2.map((item, index) => (
                    <div key={index} className="cenovnik-item mb-2">
                        <div className="row g-0">
                            <div className="col-md-4 col-6 naziv">
                                <h5 className='naziv'> {item.naziv}</h5>
                            </div>
                            <div className="col-md-4  col-3 cena">
                                <h5 className='cenatitle'>{item.cenaOrdinacija}</h5>
                            </div>
                            <div className="col-md-4  col-3 cena">
                                <h5 className='cenatitle'>{item.cenaTerena}</h5>
                            </div>
                        </div>
                    </div>
                ))}
                <h5 className='mt-4 mb-4 pregled'><b>PREGLED PSIHOLOGA</b></h5>

                <div className="cenovnik-header mb-2">
                    <div className="row">
                        <div className="col-md-4 ">
                            <h5 className='cenatitle nazivusl'>NAZIV USLUGE</h5>
                        </div>
                        <div className="col-md-4 ">
                            <h5 className='cenatitle'>U ORDINACIJI</h5>
                        </div>
                        <div className="col-md-4">
                            <h5 className='cenatitle'>NA TERENU</h5>
                        </div>
                    </div>
                </div>
                {cenovnikData3.map((item, index) => (
                    <div key={index} className="cenovnik-item mb-2">
                        <div className="row g-0">
                            <div className="col-md-4 col-6 naziv">
                                <h5 className='naziv'> {item.naziv}</h5>
                            </div>
                            <div className="col-md-4  col-3 cena">
                                <h5 className='cenatitle'>{item.cenaOrdinacija}</h5>
                            </div>
                            <div className="col-md-4  col-3 cena">
                                <h5 className='cenatitle'>{item.cenaTerena}</h5>
                            </div>
                        </div>
                    </div>
                ))}
                <h5 className='mt-4 mb-4 pregled'><b>MEDICINSKO TEHNIČARSKE USLUGE</b></h5>

                <div className="cenovnik-header mb-2">
                    <div className="row">
                        <div className="col-md-4 ">
                            <h5 className='cenatitle nazivusl'>NAZIV USLUGE</h5>
                        </div>
                        <div className="col-md-4 ">
                            <h5 className='cenatitle'>U ORDINACIJI</h5>
                        </div>
                        <div className="col-md-4">
                            <h5 className='cenatitle'>NA TERENU</h5>
                        </div>
                    </div>
                </div>
                {cenovnikData4.map((item, index) => (
                    <div key={index} className="cenovnik-item mb-2">
                        <div className="row g-0">
                            <div className="col-md-4 col-6 naziv">
                                <h5 className='naziv'> {item.naziv}</h5>
                            </div>
                            <div className="col-md-4  col-3 cena">
                                <h5 className='cenatitle'>{item.cenaOrdinacija}</h5>
                            </div>
                            <div className="col-md-4  col-3 cena">
                                <h5 className='cenatitle'>{item.cenaTerena}</h5>
                            </div>
                        </div>
                    </div>
                ))}

            </div>
        </div>
    );
};

export default Cenovnik;
