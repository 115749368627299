import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './components/Header';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import PrivacyPolicy from './pages/privacy-policy'
import CookiesPolicy from './pages/cookies-policy';


function App() {
  return (
    <div className='app'>
    <Header />


    <Routes>
     <Route exact path="/" element={<Home />} />
     <Route path="/privacy-policy" element={<PrivacyPolicy />} />
     <Route path="/cookies-policy" element={<CookiesPolicy />} />

    </Routes>
    
    </div>
  );
}

export default App;
