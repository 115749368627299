import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../App.css';
import data from '../data/slajderusluge.json';

const UslugeSlider = () => {
    const [sliderData, setSliderData] = useState([]);

    useEffect(() => {
        setSliderData(data);
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
              breakpoint: 768,
              settings: {
                dots: false,
                slidesToShow: 2,
                slidesToScroll: 2,
              }
            },
            {
              breakpoint: 480,
              settings: {
                dots: false,
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            }
          ]
    };

    return (
        <div className='uslugeback pt-5 pb-5'>
        <div className="container">
        <h1 className='tim'>USLUGE</h1>
            <Slider {...settings}>
                {sliderData.map((item, index) => (
                    <div key={index} className="card-usl m-0">
                            <div className="card-img-top-container">
                                <img className="card-img-top" src={item.image} alt={`Slide ${index + 1}`} />
                            </div>
                        <div className="card-body">
                            <h5 className="card-title2 mt-3"><b>{item.title}</b></h5>
                            <ul className="p-3 pt-0 lista">
                                {item.items.map((text, i) => (
                                    <li key={i}>{text}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
        </div>
    );
};

export default UslugeSlider;
