import React from 'react';
import Slider from 'react-slick';
import doctorData from '../data/doctorData.json';
import '../App.css';

const DoctorSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          dots: false,
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <div className="doctor-slider-container">
      <div className="container py-5">
        <h1 className='tim'>STRUČNI TIM</h1>
        <Slider {...settings}>
          {doctorData.map((doctor, index) => (
            <div key={index} className="slide-item">
              <div className="doctor-card p-3">
                <div className="doctor-info">
                  <h5 className="card-title1"><b>{doctor.name}</b></h5>
                  <p className="card-text1">{doctor.position}</p>
                </div>
                <div className="doctor-image-container">
                  <img src={doctor.image} alt={doctor.name} className="card-img-top doctor-image" />
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default DoctorSlider;
