import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import partnersData from '../data/partners.json';

const Partneri = () => {
    const [partners, setPartners] = useState([]);

    useEffect(() => {
        setPartners(partnersData);
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 7000

    };

    return (
        <div className='uslugeback pb-5'>
            <div className="partneri ">
                <div className="container">
                    <h3 className="text-center mb-4 tim">NAŠI PARTNERI</h3>
                    <Slider {...settings}>
                        {partners.map((partner, index) => (
                            <div key={index} className="partner-slide">
                                <img src={partner.logo} alt={`Partner ${index + 1}`} className=" d-inline img-fluid mb-3 " />
                                <p className="text-center"><b>{partner.text}</b></p>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    );
};

export default Partneri;
