import React, { useState } from 'react';
import novostiData from '../data/novosti.json';

const Novosti = () => {
  const [showDetails, setShowDetails] = useState({});

  const toggleDetails = (index) => {
    setShowDetails((prevDetails) => ({
      ...prevDetails,
      [index]: !prevDetails[index],
    }));
  };

  return (
    <div className='cont1'>
      <div className='container'>
        <h1 className='pt-5 tim'>NOVOSTI</h1>
        <div className="novosti-component pb-4 pt-4">
          {novostiData.map((item, index) => (
            <div key={index} className="novosti-content m-5">
              <h4>{item.title}</h4>
              <div className='nov' dangerouslySetInnerHTML={{ __html: item.text }} />
              {/* <p>{item.text}</p> */}
              {showDetails[index] && (
                <div>
                  <div className='nov' dangerouslySetInnerHTML={{ __html: item.text1 }} />
                  {/* <p>{item.text1}</p> */}
                </div>
              )}
               {item.text1 && item.text1.trim() !== '' && (
              <button onClick={() => toggleDetails(index)}>
                {showDetails[index] ? 'Pročitaj manje' : 'Pročitaj više...'}
              </button>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Novosti;
