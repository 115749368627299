import React from 'react';
import { FaHome, FaUserMd, FaClock } from 'react-icons/fa';
import '../App.css'; // Uverite se da uvozite CSS fajl

const Info = () => {
  const infoData = [
    {
      icon: <FaHome size={42} className="card-icon" />,
      title: "Iz udobnosti Vašeg doma",
      text: "Medicinsko tehničke radnje možete dobiti i u Vašem domu (patronaža). Organizovaćemo za Vas preglede lekara saradnika u ordinaciji ili kao kućnu posetu (lekar opšte medicine)."
    },
    {
      icon: <FaUserMd size={42} className="card-icon" />,
      title: "Posvećen tim lekara",
      text: "Naš stručni kadar i saradnici će se potruditi da Vam lečenje prođe manje traumatično i bolno. Zapošljavamo proveren i stručan kadar koji će Vam pružiti usluge po vrhunskom standardu, koji poštuje sve svetske protokole."
    },
    {
      icon: <FaClock size={42} className="card-icon" />,
      title: "Vaše vreme nam je bitno",
      text: "U dogovoreno vreme na dogovorenom mestu. Sprovodimo produžetak bolničke terapije i lečenja kao i sve druge medicinsko-tehničke radnje, u našoj ordinaciji ili u kući pacijenta."
    }
  ];

  return (
    <div className='infocont'>
    <div className="container">
      <div className="row justify-content-center">
        {infoData.map((info, index) => (
          <div key={index} className="col-md-4 mb-4">
            <div className="card text-left">
              <div className="card-body">
                <div className="mb-3 element">{info.icon}</div>
                <h5 className="card-title">{info.title}</h5>
                <p className="card-text">{info.text}</p>
              </div>
            </div>
          </div>
          
        ))}
      </div>
    </div>
    </div>
  );
}

export default Info;
