import React from 'react';
import uslugeData from '../data/usluge.json';
import '@fortawesome/fontawesome-free/css/all.min.css';
import backgroundImg from '../img/adult_4402808.jpeg'; // Uvezite sliku koju želite da koristite kao pozadinu
import '../App.css';

const Usluge = () => {
    return (
        <div className="container-fluid">
            <div className="row">
                {/* Fiksna pozadina */}
                <div className="fixed-background p-0">
                    <img src={backgroundImg} alt="Background" className="background-image" />
                </div>

                {/* Providna zelena pozadina za usluge */}
                <div className="col-md-6 mt-4 mb-4 offset-md-6 transparent-green-background">
                    <div className="row">
                        <div className="col-md-12 nna">
                            <h4 className="text-left naslov">{uslugeData.naslov}</h4>
                            <p className="text-left p1">{uslugeData.opis}</p>
                        </div>
                        {uslugeData.usluge.map((usluga, index) => (
                            <div key={index} className="col-md-6 ">
                                <div className="service-card">
                                    <div className='element'>
                                    <i className={usluga.icon} ></i></div>
                                    <h2>{usluga.title}</h2>
                                    <ul>
                                        {usluga.items.map((item, idx) => (
                                            <li key={idx}>{item}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Usluge;
