import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import slika1 from '../img/banner1.jpeg';
import slika2 from '../img/banner2.jpeg';
import slika3 from '../img/banner3.jpeg';
import '../App.css';
import { HashLink as NavLink } from 'react-router-hash-link';

const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', right: '10px', fontSize: '3rem' }}
      onClick={onClick}
    />
  );
};

const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', left: '10px', zIndex: 1, fontSize: '3rem' }}
      onClick={onClick}
    />
  );
};


const Slajder = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };

  return (
    <div>
      <div className="slider-container">
        <Slider {...settings}>
          <div className="slide align-bottom">
            <img src={slika1} alt="Slika 1" style={{ width: '100%', height: 'auto' }} />
            <div className="slide-text">
              <div className='t1'>
                <h2>Mobi Med</h2>
                <h2 className='zelen'>Mobi med, specijalistička lekarska ordinacija u službi Vašeg zdravlja</h2>
                <p>Pregledi lekara specijalista, hirurške intervencije, medicinsko tehničarske usluge</p>
              </div>
            </div>
          </div>
          <div className="slide align-bottom">
            <img src={slika3} alt="Slika 3" style={{ width: '100%', height: 'auto' }} />
            <div className="slide-text">
              <div className='t1 t2'>
                <h2>Mobi Med</h2>
                <h2 className='zelen'>Mobi med, specijalistička lekarska ordinacija u službi Vašeg zdravlja</h2>
                <p>Pregledi lekara specijalista, hirurške intervencije, medicinsko tehničarske usluge</p>
              </div>
            </div>
          </div>
          <div className="slide align-bottom">
            <img src={slika2} alt="Slika 2" style={{ width: '100%', height: 'auto' }} />
            <div className="slide-text">
              <div className='t1'>
                <h2>Mobi Med</h2>
                <h2 className='zelen'>Mobi med, specijalistička lekarska ordinacija u službi Vašeg zdravlja</h2>
                <p>Pregledi lekara specijalista, hirurške intervencije, medicinsko tehničarske usluge</p>
              </div>
            </div>
          </div>
        </Slider>
      </div>
      <div className="static-image">
        <img src={slika1} alt="Slika 1" className='slika-slajd' />
        <div className="slide-text">
          <div className='t1'>
            <h2>Mobi Med</h2>
            <h2 className='zelen1'><b>Mobi med, specijalistička lekarska ordinacija u službi Vašeg zdravlja</b></h2>
          </div>
          <button className='contbtn align-center align-bottom mt-4'><NavLink className="nav-link" smooth to="#Contact">Zakažite pregled već sad!</NavLink></button>
        </div>
       
      </div>
    </div>
  );
}

export default Slajder;
