import React, { useRef, useState } from 'react';
import validateForm from "../components/validation"; // Uvoz funkcije za validaciju

const Newsletter = () => {
    const formRef = useRef();
    const [formData, setFormData] = useState({
      message: "",
      to: "urosgrujic159@gmail.com"
    });
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [submitError] = useState(false); // Stanje za prikaz poruke o grešci
  
  
  
    const sendContactMessage = (event) => {
      if (!validateForm(formRef.current)) return;
      const _formData = new FormData();
      for (var i in formData) {
        _formData.append(i, formData[i]);
      }
      fetch('https://appsterdev.cubenet.rs/newsletter.php', { method: 'POST', body: _formData }).then(res => res.text());
      setFormSubmitted(true);
    };
  
    const changeHandler = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    return (
        <div className='uslugeback pb-4 pt-4'>
        <div className='container'>
            <h3 className='tim3 pb-1'>Prijavite se na naš newsletter</h3>
            <h3 className='news1 pb-4 mt-4'>Prijavljivanjem na Newsletter listu, saznaćetete najnovije aktivnosti, promocije i druge novosti naše ordinacije!</h3>
            {!formSubmitted && !submitError && (
                <form onSubmit={sendContactMessage} className="row g-3 mx-auto" action="#" name="contact-form" ref={formRef}>
                    <div className="col-md-4 col-12">
                        
                        <input type="text" id="name" name="name" className="form-control1" placeholder="Ime i prezime" required onChange={changeHandler}/>
                    </div>
                    <div className="col-md-4 col-12">
                        
                        <input type="email" id="emailid" name="email" className="form-control1" defaultValue="" placeholder="Email adresa" required onChange={changeHandler}/>
                    </div>
                    <div className="col-md-3 col-12 d-flex align-items-end">
                        <button type="submit" className="btn btn-primary btnnews mt-md-0 mt-2">Pošalji</button>
                    </div>
                </form>
                )}
                {formSubmitted && ( // Prikazi poruku nakon uspešnog slanja
                  <h5 className="w-100 my-5 text-black text-center">Hvala vam što ste se prijavili!</h5>
                )}
                {submitError && ( // Prikazi poruku o grešci ako se desi greška pri slanju
                  <h5 className="w-100 my-5 text-vlack text-center">Došlo je do greške prilikom slanja prijave. Molimo vas, pokušajte ponovo kasnije.</h5>
                )}
            </div>
        </div>
    );
};

export default Newsletter;
