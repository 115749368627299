import React, { useState } from 'react';
import '../App.css';
import slika1 from '../img/016-2-.jpeg';
import slika2 from '../img/020-1-.jpeg';
import slika3 from '../img/07-1-.jpeg';


const Onama = () => {
    const [showDetails, setShowDetails] = useState(false); // Stanje koje prati prikazivanje detalja

    const toggleDetails = () => {
        setShowDetails(!showDetails); // Funkcija za promenu stanja pri pritisku na dugme
    };

    return (
        <div className='cont1 pb-5'>
            <div className='container'>
                <h3 className='pt-5 tim'>O NAMA</h3>
                <div className="novosti-component pb-4 pt-4">
                    <div className="novosti-content m-5">
                        <p><i className="fas fa-id-card" style={{ color: 'lightgray' }}></i> Dr Ljubomir Kontić</p>
                        <p>Dragi naši,<br />
                            Kao ordinacija smo novi, ali smo mi, u ovom gradu, nadam se, stari znanci. Iza mene je 27 godina rada kao specijaliste opšte hirurgije u Opštoj bolnici Pančevo.</p>
                        {showDetails && (
                            <div>
                                <p>Pre toga, a po završetku Medicinskog fakulteta u Beogradu 1979 godine, do dobijnja speciajlizacije sam radio kao lekar opšte medicine u Zdravstvenoj stanici u Banatskom Novom Selu, Omoljici i Ambulanti „Gornji grad“ u Pančevu, gde sam imao prilike da se se susretnem sa različitom strukturom i patologijom pacijentata. “Ko nije pekao zanat u seoskoj ambulanti - taj nije shvatio čari medicine“.
                                    Od 1988 godine pa sve do penzije 2015 godine provodim na odelenju hirurgije u Opštoj bolnici Pančevo. Mnogi od Vas su imali prilike da se sretnu sa mnom za vreme moje aktivne lekarske službe.
                                    1989 godine sam završio edukaciju za AV fistule na Urološkoj klinici KC Srbije u Beogradu. Edukaciju iz Laparaskopske hirurgije završio sam u KBC Dr Dragiša Mišović i OB Senta. Senta je mali grad, ali u to vreme, veliki centar laparoskopske hururgije. Samostalno laparaskomskom hirurgijom žučne kese sam kao pionir u Pančevu počeo da se bavim davne 2001 godine.
                                    Objavio sam i prezentovao, kao autor i ko-autor, više stručnih radova na kongresima hirurga širom bivše Jugoslavije, kao i na Međunarodnom kongresu o bolestima jetre. Radio sam i kao stručni saradnik na Stomatološkom fakultetu u Pančevu vodeći vežbe studentima, u okviru praktične nastave iz opšte hirurgije.
                                    Bio sam i član Predsedništva Hirurške sekcije Srpskog lekarskog društva. Tokom višegodišnjeg hiruškog rada obavio sam veliki broj laparaskopskih operacija žučne kese, kao i operacija otvorene abdominalne hirurgije.
                                    U okviru traumatološke urgentne hirurgije uspešno sam izvodio operacije kod povreda abdominalnih organa, krvnih sudova, kao i povreda srca i pluća. Tokom svog skromnog hiruškog rada trudio sam se da Vam pružim stručnu pomoć, da Vas razumem i shvatim kao lekar i kao čovek i ljudski prihvatim sve Vaše probleme, kao i Vaše kritike i pohvale.
                                    Hvala Vam na dugogodišnjem poverenju.
                                    Srdačno uvek Vaš Dr Ljubomir Kontić</p>
                            </div>
                        )}
                        <button  onClick={toggleDetails}>Prikaži više</button>
                    </div>
                
                <div className="row m-5 mb-0">
                    <div className="col-md-4 mb-4">
                        <div className="image-container">
                            <img src={slika1} alt="Slika 1" className="img-fluid gallery-image" />
                            <div className="overlay">
                                <div className="overlay-text">Mobi Med Ordinacija</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 mb-4">
                        <div className="image-container">
                            <img src={slika2} alt="Slika 2" className="img-fluid gallery-image" />
                            <div className="overlay">
                                <div className="overlay-text">Mobi Med Ordinacija</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 mb-4">
                        <div className="image-container">
                            <img src={slika3} alt="Slika 3" className="img-fluid gallery-image" />
                            <div className="overlay">
                                <div className="overlay-text">Mobi Med Ordinacija</div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    );
};

export default Onama;
